$primary-color: #003C76;
$secondary-color: #80A1C9;
$accent-color: #C1002B;
$green: #0F8553;
$success-color: #003C76;
$error-color: #C1002B;

$error-color-light: #ff93ab;
$default-border-radius: 3px;
$light-grey: #646363;
$light-background: #e7e4e4;
$ultra-light-grey: #d6d5d5;
$default-max-width: 600px;