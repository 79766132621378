@import 'variables.scss';

button {
    border-radius: $default-border-radius;
    height: 45px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.white {
    color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px white !important;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px white !important;
}

.grey,
mat-card.mat-card.grey {

    color: grey !important;
    background-color: rgba(255, 255, 255, 0.7) !important;

    &.bg.hue {
        background-color: grey !important;

        color: white !important;

        p {
            color: white !important;
        }
    }

    a {
        background-color: grey !important;
        opacity: .5;
        color: white !important;
    }
}

mat-card.mat-card.disabledCard {

    color: grey !important;
    background-color: rgba(255, 255, 255, 0.7) !important;

    &.bg.hue {
        background-color: grey !important;

        color: white !important;

        p {
            color: white !important;
        }
    }

    a {
        background-color: grey !important;
        opacity: .5;
        color: white !important;
        pointer-events: none;
        cursor: default;
        text-decoration: none;
    }
}

h4 {
    margin-bottom: 10px;
    font-size: 12px;
}

.headerContainer {
    padding: 20px;

    margin-top: 100px !important;

    @media (max-width: 375px) {
        margin-top: 55px !important;
    }

    .headerCard {
        margin-bottom: 20px;

        @media (max-width: 1023px) {
            margin-bottom: 0;
        }
    }
}

.error {
    color: $error-color !important;

    & p {
        // color: adjust-hue($error-color, -30%)!important;
        color: adjust-color($error-color, $saturation: -75%) // opacity: .75;
    }

    &.bg.hue {
        background-color: $error-color !important;

        color: white !important;

        p {
            color: white !important;

        }
    }
}

.mat-slide-toggle-thumb-container {
    z-index: 0 !important;
}

.success {
    color: $success-color !important;

    &.bg.hue {
        background-color: $success-color !important;

        color: white !important;

        p {
            color: white !important;

        }
    }
}

.primary {
    color: $primary-color !important;

    &.bg.hue {
        background-color: $primary-color !important;

        color: white !important;

        p {
            color: white !important;

        }
    }
}

.green {
    color: $green !important;

    &.bg.hue {
        background-color: $green !important;

        color: white !important;

        p {
            color: white !important;

        }
    }
}

.accent {
    color: $accent-color !important;

    &.bg.hue {
        background-color: $accent-color !important;

        color: white !important;

        p {
            color: white !important;
        }
    }
}

.greyArrow {
    color: grey !important;
    opacity: .75;
}

.boring {
    color: white !important;

    &.bg.hue {
        background-color: white !important;

        color: black !important;

        p {
            color: black !important;
        }
    }
}

.light-grey {
    color: $light-grey !important;
}

.mat-card-actions {
    padding: 0 !important;
}

.mat-card-global {

    background-color: transparent !important;
    width: 100%;
    margin-bottom: 0px;
    border-radius: $default-border-radius !important;

    &.bg-content {
        background-color: white !important;
        // margin: 0 20px 30px 20px;
        width: 100% !important;
        margin-bottom: 25px !important;
    }

    &.bg-content:last-child {

        margin-bottom: 0px !important;
    }

    h1 {
        line-break: 1.1em;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h2 {
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

h1 {
    font-weight: 500;
    font-size: 24px !important;
}

h3 {
    font-weight: 500;
}

h2 {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 1.5em !important;


    &.mat-headline {
        font-size: 20px !important;

    }
}

mat-expansion-panel {
    border-radius: 0 !important;
}

.mat-flat-button,
button,
.mat-button {
    margin-left: 0 !important;
    border-radius: $default-border-radius !important;
    width: 100%;
    // max-width: 500px;
}

// button {
//     width: 100%!important;
// }

mat-card-title {
    margin-left: -8px
}

mat-card-subtitle {
    margin-left: -8px;
    font-weight: 100;
}

.mat-card-header-text {
    margin-left: 10px !important;
}

.normalize-button {
    margin-left: 0 !important;
}

.justFontHeader {
    color: white;
    margin: 0 0 20px 0;
}

mat-spinner {
    margin: 0 auto;
}

.snack-bar-color-success,
.success-color {
    color: $success-color;
}

.snack-bar-color-error,
.error-color {
    color: $error-color-light;
}

.fullWidth {
    width: 100%;
}

.img-wrapper {
    position: relative;

    border-radius: $default-border-radius $default-border-radius 0 0;

    .img-text-followup {
        color: white;
        position: absolute;
        z-index: 0;
        bottom: 10px;
        font-size: 40px;
    }
}

.full-width-dialog {
    max-width: $default-max-width !important;
    max-height: 100vh !important;
    padding: 0 !important;
}

.legal-dialog {
    max-width: 90vw !important;
    max-height: 100vh !important;
    padding: 0 !important;
}

mat-dialog-container {
    padding-top: 0 !important;
}

.mat-form-field {
    width: 100%;
}

.mat-radio-label {
    width: 100% !important;
}

.highlight {
    color: $primary-color;
}

.titleWrapper {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    height: auto;
    box-sizing: border-box;
    padding: 36px;
    z-index: 999;
    -webkit-box-shadow: 0px 3px 26px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0px 3px 26px 0px rgba(50, 50, 50, 0.1);
    box-shadow: 0px 3px 26px 0px rgba(50, 50, 50, 0.1);

    @media (max-width: 375px) {
        padding: 20px 36px;
    }

    &.bigScreen {
        width: $default-max-width;
        padding: 36px;
        box-sizing: border-box;
        height: auto;
        max-width: $default-max-width;
        margin: 0 auto;
        left: 50%;
        margin-left: calc(#{$default-max-width} / -2);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    h1 {
        padding-right: 20px;
        margin: 0;
        color: black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.normalize-button.primary.bg.hue.disabledButton {
    background-color: $light-grey !important;
    // color: $light-grey!important;
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    opacity: .5;
}

.mat-menu-content {
    min-width: 190px !important;
}

.threeLittleDots {
    position: absolute !important;
    z-index: 9999 !important;
    color: $primary-color !important;
    right: 20px !important;
    top: 28px !important;
    width: 45px !important;

    @media (max-width: 375px) {
        top: 16px !important;
    }

    @media (max-width: 599px) {
        position: fixed !important;
    }

    &.login {
        color: $primary-color !important;
    }
}

a.mat-flat-button {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.mat-flat-button {
    margin-right: 0 !important;
}

mat-card-content {
    overflow: hidden;
}

.qrWrapper {
    max-width: 400px !important;
    margin: 0 auto !important;
}

ul {
    li {
        color: $light-grey;
    }
}

@media print {
    .titleWrapper {
        display: none;
    }

    html,
    body,
    .mobileNavLayout {
        background-color: white !important;
    }

    button,
    .mat-flat-button {
        display: none;
    }

    #qrWrapper {
        &.qrWrapper {
            max-width: auto !important;
            margin: 0 !important;
        }
    }

    .qrPrint {
        position: relative;
        left: -17px;
        top: -20px;
        display: block !important;
        margin-bottom: -40px !important;
    }

    .qrCode {
        display: none;
    }

    .mat-card {

        p,
        h1,
        h2,
        h3,
        h4,
        .highlight {
            color: black !important;
        }
    }

    .qrButtons {
        display: none !important;
    }

    .headerContainer {
        margin-top: 0 !important;
    }
}

span.mat-radio-label-content {
    white-space: normal !important;
}

.staging {
    background-color: rgb(194, 0, 0);

    h1 {
        span {
            &.highlight {
                color: white !important;
            }
        }
    }
}

.stagingHeader {
    left: 0;
    height: 32px;
    position: absolute;
    width: 100%;
    top: 0;
    background-color: red;
    text-align: center;

    p {
        margin: 0;
        color: white;
        position: relative;
        top: 6px;
    }
}